.title {
  white-space: nowrap;
  overflow: hidden;
  width: 30em;
  animation: type 8s steps(60, end); 
}
  
@keyframes type{ 
  from { width: 0; } 
} 

.post {
  height: calc(100vh - 80px);
  filter: brightness(49%);
}

.detail {
  font-size: x-large;
  line-height: 1.8em;
}